<!-- 企业新闻 -->

<template>
    <div>
      <div class="news-page">
        <img src="../../assets/images/news/news-ban.png" />
        <div class="news-content">
            <div class="left-nav">
              <router-link to="/">首页</router-link>
              <i>&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;</i>
              <router-link to="presscenter">新闻中心</router-link>
              <i>&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;</i>
              <router-link to="corporateNews" >企业新闻</router-link>
            </div>

        </div>
        <div class="news-main">
          <div class="news-content">
            <div class="news-box">
              <ul>
                <li>
                  <div @click="addskip1002" >
                    <h2>年度上新！苏州人才政策申报月历（2024版）来啦→</h2>
                    <div class="time"> 2024-02-29</div>
                    <div class="font"></div>
                  </div>
                </li>
                <li>
                  <div @click="addskip1001" >
                    <h2>苏州腾康签约中国-沙特投资大会</h2>
                    <div class="time"> 2023-12-15</div>
                    <div class="font"></div>
                  </div>
                </li>
                <li>
                <div @click="addskip0">
                  <h2>苏州腾康环保科技有限公司作为有机垃圾处理行业的领跑者，应邀参加第24届中国环博会。</h2>
                  <div class="time">2023-03-10</div>
                  <div class="font"></div>
                </div>
              </li>
                <li>
                <div @click="addskip1">
                  <h2>苏州腾康环保科技有限公司受邀参加赣州市厨余垃圾资源化利用培训交流会</h2>
                  <div class="time">2023-03-10</div>
                  <div class="font"></div>
                </div>
              </li>
                <li>
                <div @click="addskip2">
                  <h2>热烈祝贺我司荣获中国城市环境卫生协会科学技术奖科技进步一等奖</h2>
                  <div class="time">2023-01-17</div>
                  <div class="font"></div>
                </div>
              </li>
                <li>
                  <div @click="addskip3">
                    <h2>2022年开工动员大会</h2>
                    <div class="time">2022-02-07</div>
                    <div class="font"></div>
                  </div>
                </li>
                <li>
                  <div @click="addskip4">
                    <h2>喜报！我司共同参与研究的论文在著名Wiley期刊成功发表
                    </h2>
                    <div class="time">2022-12-08</div>
                    <div class="font"></div>
                  </div>
                </li>
                <li>
                  <div @click="addskip5">
                    <h2>苏州腾康环保科技有限公司完美亮相第22届中国环博会</h2>
                    <div class="time">2021-04-26</div>
                    <div class="font"></div>
                  </div>
                </li>

              </ul>
              <div class="paging">
                <el-pagination  :total="6" show-less-items/>
              </div>

            </div>
            <div class="news-class">
              <h4>推荐新闻</h4>
              <router-link to="corporateNews" style="background:#3fa4e7;color:#fff;">企业新闻</router-link>
              <router-link to="industryTrends">业界动态</router-link>
            </div>
          </div>
            </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {};
    },
    methods:{
      addskip1002() {
        this.$router.push({
          path: "./newsDetail",
          query: {
            id: 1002,
          },
        });
      },
      addskip1001() {
        this.$router.push({
          path: "./newsDetail",
          query: {
            id: 1001,
          },
        });
      },
      addskip0() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 0,
        },
      });
    },
    addskip1() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 1,
        },
      });
    },
    addskip2() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 2,
        },
      });
    },
    addskip3() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 3,
        },
      });
    },
    addskip4() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 4,
        },
      });
    },
    addskip5() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 5,
        },
      });
    },
    addskip6() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 6,
        },
      });
    },
    addskip7() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 7,
        },
      });
    },
    addskip8() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 8,
        },
      });
    },
    addskip9() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 9,
        },
      });
    },
    addskip10() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 10,
        },
      });
    },
    addskip11() {
      this.$router.push({
        path: "./newsDetail",
        query: {
          id: 11,
        },
      });
    },
  }
  };
  </script>
  <style scoped>
    .news-page img{
      display: block;
      margin: auto;
    }
    .news-class{
      width: 23%;
      background: #fff;
      float: left;
      overflow: hidden;
      margin-left: 20px;
    }
    .news-class h4{
      font-size: 22px;
      background: #8cc6ee;
      color: #fff;
      padding: 10px 10px;
      border-bottom: 1px solid #ccc;
  }
  .news-class a{
    width: 94%;
      margin: auto;
      display: block;
      padding: 10px 0 10px 20px;
      color: #444;
      border-bottom: 1px solid #eee;
      font-size: 16px;
  }

    .paging{
      margin: auto;
      width: 94%;
      padding: 50px 10px;
      text-align: center;
    }
    .news-box ul li{
      width: 100%;
      margin-bottom: 20px;
      border-bottom: 1px dotted #0e6eb8;
      float: left;
    }
    .news-box ul li h2{
      font-size: 20px;
      color: #222;
      line-height: 44px;
      overflow: hidden;
      height: 44px;
      transition: color .3s;
    }
  .news-content .news-box{
      width:74%;
      float: left;
    }
    .news-box ul li a{
      display: block;
      width: 100%;
      padding: 20px 0;
    }
     .news-box ul li .time{
      color: #999;
      line-height: 26px;
      font-size: 14px;
      padding-left: 30px;
      background: url(../../assets/images/news/case31\ \(1\).png) no-repeat left center;
     }
    .news-box ul li .font{
      font-size: 14px;
      color: #666;
      line-height: 24px;
      overflow: hidden;
    }
    .news-main{
      width: 100%;
      overflow:hidden;
      background: #fff;
      padding:10px;
    }
    .news-page{
      min-width: 1250px;
      height: 100%;
      overflow: hidden;
      margin: auto;
      padding: 0;
      background: #fff;
    }
    .news-content{
      width: 1300px;
      margin: auto;
    }
    .left-nav{
    float: left;
    padding-left: 20px;
    margin-top: 30px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    font-weight: bold;
    color: #000;
    margin-bottom:10px;
  }
  </style>
